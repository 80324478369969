.flex15-wrapper {
    display: flex;
    justify-content: center;
}

.flex15-wrapper>example-box {
    padding: 0 10px;
    min-width: 80px;
}

.flex15-align-self-1 {
    align-self: flex-end;
    margin-right: 10px;
}

.flex15-align-self-2 {
    align-self: center;
    margin-right: 10px;
}

.flex15-align-self-3 {
    align-self: flex-start;
    margin-right: 10px;
}

.flex15-align-self-4 {
    align-self: flex-end;
    margin-right: 10px;
}