.flex20-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex20-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 265px;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex20-no-wrap {
    display: flex;
    flex-wrap: nowrap;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: auto;
}

.flex20-no-wrap {
    padding: 10px;
}

.flex20-no-wrap>example-box {
    margin-right: 10px;
}

.flex20-no-wrap>example-box:last-child {
    margin-right: 0;
}

.flex20-wrap>example-box {
    margin: 10px 0 10px 10px;
}

.flex20-wrap>example-box:nth-child(5),
.flex20-wrap>example-box:last-child {
    margin: 0 0 10px 10px;
}