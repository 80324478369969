.flex3-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex3-wrapper-inner > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    width: 50px;
    height: 50px;
    background: #79d8e4;
    border: 2px solid #fff;
}