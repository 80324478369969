/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

 pre.language-markup {
	border-left: 1px solid #5d5d5d;
	border-radius: 3px;
}

.language-css:first-child {
   border-top-left-radius: 3px;
   border-top-right-radius: 3px;
}

 code[class*="language-"],
 pre[class*="language-"] {
	 background: none;
	 font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	 text-align: left;
	 white-space: pre;
	 word-spacing: normal;
	 word-break: normal;
	 word-wrap: normal;
	 line-height: 1.5;
	 -moz-tab-size: 4;
	 -o-tab-size: 4;
	 tab-size: 4;
 
	 -webkit-hyphens: none;
	 -moz-hyphens: none;
	 -ms-hyphens: none;
	 hyphens: none;
 
 }
 
 /* Code blocks */
 pre[class*="language-"] {
	 padding: 1em;
	 margin: .5em 0;
	 overflow: auto;
	 border: 1px solid #5d5d5d;
	 margin: 0;
	 transition: border 0.5s ease;
 }
 
.language-markup {
	margin: 12px 0 !important;
	border-radius: 3px;
}
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
	 padding: .1em;
	 border-radius: .3em;
	 white-space: normal;
 }
 
 .token.comment,
 .token.block-comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
	 color: #999;
 }
 
 .token.tag,
 .token.attr-name,
 .token.namespace,
 .token.deleted {
	 color: #ed8fa9;
 }
 
 .token.function-name {
	 color: #6196cc;
 }
 
 .token.boolean,
 .token.number,
 .token.function {
	 color: #f08d49;
 }
 
 .token.property,
 .token.class-name,
 .token.constant,
 .token.symbol {
	 color: #7ec699;
 }
 
 .token.selector,
 .token.important,
 .token.atrule,
 .token.keyword,
 .token.builtin {
	 color: #ed8fa9;
 }
 
 .token.string,
 .token.char,
 .token.attr-value,
 .token.regex,
 .token.variable {
	 color: #7ec699;
 }
 
 .token.operator,
 .token.entity,
 .token.url {
	 color: #67cdcc;
 }
 
 .token.important,
 .token.bold {
	 font-weight: bold;
 }
 .token.italic {
	 font-style: italic;
 }
 
 .token.entity {
	 cursor: help;
 }
 
 .token.inserted {
	 color: green;
 }