.splash {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    height: 100vh;
    width: 100%;
    opacity: 0;
    animation: fadeIn 0.8s linear forwards;
}

.splash-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.splash-logo {
    font-size: 33px;
    color: #fbfbfb;
    margin-bottom: 50px;
}

.splash-logo>span {
    color: #d84973;
}

.splash-logo>.fas {
    color: #d84973;
    margin-right: 10px;
}

.splash-dot-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 50px;
}

.splash-fade-out {
    animation: fadeOut 0.8s linear forwards;
}

/* Spinner one */

.spinnerOne-init:before,
.spinnerOne-init:after {
    content: "";
    position: relative;
    display: block;
}

.spinnerOne-init:before {
    animation: spinnerOne 2.05s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    width: 52px;
    height: 52px;
    background-color: #fbfbfb;
}


/* Spinner two */

.box {
    animation: spinnerTwo 0.5s linear infinite;
    width: 52px;
    height: 52px;
    background: #fbfbfb;
    border-radius: 3px;
}