.menu {
    width: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fbfbfb;
    box-shadow: 0 0 20px #bf7fdd;
    border-right: 3px solid #dcd7fa;
    margin-bottom: 65px;
}

.menu-content {
    font-family: 'Inconsolata', monospace;
    padding: 10px;
    color: #6b6b6b;
}

.menu-spacer {
    height: 50px;
    width: 100%;
}

.menu-title {
    font-size: 25px;
    color: #e87d9a;
    padding: 10px 0;
    cursor: pointer;
    user-select: none;
}

.menu-title>.fas {
    color: #d4d4d4;

}

.menu-links-wrapper {
    color: #333;
    margin-left: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-left: 1px dashed #b5b5b5;
}

.menu-link {
    display: block;
    padding: 7px 0;
    letter-spacing: 1px;
    font-size: 17px;

}

.menu-links-wrapper>a:link,
.menu-links-wrapper>a:visited {
    color: #333;
    text-decoration: none;
}

.menu-links-wrapper>a:hover,
.menu-links-wrapper>div:hover {
    color: #e85f84;
    user-select: none;
    cursor: pointer;
}

.menu-link>.fas {
    color: #a7a4d7;

}

.menu-link:hover>.fas {
    color: #7e7abd;
    user-select: none;
    cursor: pointer;
}

.menu-hide > .menu-links-wrapper {
    display: none;
}

.menu-hide > .menu-title {
    padding-bottom: 0px;
}

.menu-show > .menu-links-wrapper {
    display: block;
}

.menu-show div .fa-angle-down {
	animation: rotateOn 0.15s linear 1 forwards;
}

.menu-hide div .fa-angle-down {
	animation: rotateOff 0.15s linear 1 forwards;
}