.css3-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.css3-hover {
    padding: 0 10px;
}

.css3-hover:hover {
    cursor: pointer;
    color: #fffdf2;
    background-color: #a89cd5;
}

.parent-1,
.parent-2,
.parent-3,
.parent-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: arial;
  background: #a89cd5;
  padding: 20px;
  margin-bottom: 2px;
}

.parent-1 > .target-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9effc1;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.child-2 + .target-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff9e9e;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.parent-3 .target-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9eecff;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.child-4 ~ .target-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc79e;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}