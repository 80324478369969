.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    font-family: 'Inconsolata', monospace;
    height: 55px;
    color: #ffffff;
    margin-top: 65px;
    font-size: 17px;
    color: #333;
    background: linear-gradient(to right, #b684d8, #dcd9f7, #b684d8);
}

.footer>a>.fa-atom {
    animation: load 3s linear infinite;
}

.footer>a>.fa-fort-awesome {
    animation: bounce 1s linear infinite;
}

.footer>.fas {
    color: #d84973;
}

.footer>a {
    color: #333 !important;
}

.footer>a:link,
.footer>a:visited {
    color: #333;
    text-decoration: none;
}

.scroll-top>.fas {
    font-size: 36px;
    color: #6b6b6b;
}

.visibility-hidden {
    visibility: hidden
}

.scroll-top-show {
    opacity: 0.7 !important;
    transition: all 0.5s ease !important;
}

.scroll-top-show:hover {
    opacity: 1 !important;
    transition: opacity 0.5s ease !important;
}

.scroll-top-hide {
    opacity: 0 !important;
    transition: opacity 0.5s ease !important;
}