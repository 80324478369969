.flex1 {
    display: flex;
    flex-direction: column;
}

.flex1-wrapper-before {
    align-items: center;
}

.flex1-wrapper-before,
.flex1-wrapper-after {
    display: flex;
    min-height: 150px;
    padding: 20px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #a89cd5;
}

.flex1-wrapper-after > div {
    display: block;
}

.flex1-wrapper-inner {
    display: flex;
    justify-content: space-around;
    flex: 1;
}

.flex1-wrapper-after>div>example-box {
    width: 50px;
}

.flex1-wrapper-after>.flex1-wrapper-inner>example-box:nth-child(2) {
    margin: 10px 0;
}

.flex1-header {
    background-color: #d0aded;
    color: #fffdf2;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.flex1-wrapper-inner > div {
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: none;
    border-bottom: none;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div:first-child {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div:last-child {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
}