.css3-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: arial;
    background: #a89cd5;
    min-height: 50px;
    padding: 20px;
}

.pseudo-child-3 {
    padding: 0 10px;
}

.pseudo-child-1,
.pseudo-child-2 {
    margin-bottom: 10px;
}

.pseudo-child-1,
.pseudo-child-2 {
    width: 289px;
    padding: 5px;
}

.pseudo-child-1::before {
    content:"::before pseudo element";
    padding: 5px;
    margin: 0 7px 0 0;
    border-radius: 3px;
    background: #ffe0ca;
    border: 2px solid #fbfbfb;
}

.pseudo-child-2::after {
    content:"::after pseudo element";
    padding: 5px;
    margin: 0 0 0 7px;
    border-radius: 3px;
    background: #ffe0ca;
    border: 2px solid #fbfbfb;
}

.pseudo-child-3::selection {
    color: #fffdf2; 
    background: #3ed9ff;
}