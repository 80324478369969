.css4-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    min-height: 50px;
    margin-bottom: 2px;
}

.css4-wrapper > example-box {
    padding: 0 10px;
}

.css4-wrapper > example-box:nth-child(2) {
    margin: 0 10px;
}

.class-child-1 {
    color: #333333;
    background-color: #ffc79e;
    cursor: pointer;
}

.class-child-1:hover {
    color: #fffdf2;
    background-color: #a89cd5;
}

.class-child-2:first-child,
.class-child-3:nth-child(1) {
    background-color: #9effc1;
}

.class-child-3:nth-child(2) {
    background-color: #ff9e9e;
}

.class-child-2:last-child,
.class-child-3:nth-child(3) {
    background-color: #9eecff;
}

.class-child-3:first-of-type {
    background-color: #9effc1;
}

.class-child-3:last-of-type {
    background-color: #9eecff;
}