.flex21-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex21-wrap {
    display: flex;
    flex-wrap: wrap-reverse;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: 265px;
}

.flex21-no-wrap {
    display: flex;
    flex-wrap: nowrap;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: auto;
}

.flex21-no-wrap {
    padding: 10px;
}

.flex21-no-wrap>example-box {
    margin-right: 10px;
}

.flex21-no-wrap>example-box:last-child {
    margin-right: 0;
}

.flex21-wrap>example-box {
    margin: 10px 0 10px 10px;
}

.flex21-wrap>example-box:nth-child(5),
.flex21-wrap>example-box:last-child {
    margin: 10px 0 0 10px;
}