.flex14-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 20px;
}

.flex14-wrapper:last-child {
    align-items: center;
}

.flex14-baseline-1 {
    padding: 55px 10px 10px;
    margin-right: 10px;
}

.flex14-baseline-2 {
    padding: 10px 10px 60px;
    margin-right: 10px;
}

.flex14-baseline-3 {
    padding: 55px 10px 10px;
    margin-right: 10px;
}

.flex14-baseline-4 {
    padding: 10px 10px 60px;
}