.sections {
    display: flex;
    justify-content: center;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 65px;
}

.sections-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin: 0 20px;
}

.section-text-list {
    border-left: 5px solid #b18bd7;
    margin-left: 6px;
    padding-left: 10px;
}

.theme {
    height: 100%;
    border-left: 5px solid #da849a;
}

.section {
    display: flex;
    background: #fbfbfb;
    border-radius: 5px;
    border-bottom: 3px solid #ffccdc;
    border-left: 3px solid #ffccdc;
}

.section-title {
    color: #6b6b6b;
    font-size: 40px;
    margin-bottom: 10px;
    font-family: 'Slabo 27px', serif;
}

.section-title > .fas {
    position: relative;
    top: 3px;
    margin-right: 6px;
    color: #ec91aa;
}

.section-spacer-top {
    height: 35px;
    width: 100%;
}

.section-spacer {
    height: 25px;
    width: 100%;
}

.section-text {
    color: #777777;
    line-height: 24px;
    margin-bottom: 20px;
}

.section-read {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;  
}

.section-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.section-copy:hover {
    margin-bottom: 20px;
    transition: all 0.2s ease;
}

.section-copy-text {
    background: #fffef8;
    margin-top: 10px;
    user-select: none;
    font-family: 'Inconsolata', monospace;
    transition: all 0.2s ease;
}

.section-copy>span {
    display: none;
}

.section-copy:hover>.fas {
    color: #8ccec3;
    transition: color 0.2s ease;
}

.section-copy:hover>.section-copy-text {
    transition: all 0.2s ease;
}

.section-copy>.fas {
    font-size: 60px;
    color: #d69cd1;
    transition: color 0.2s ease;
}

.section-read>.fas {
    font-size: 60px;
    color: #8ccec3;
}

.section-content {
    flex: 1;
    padding: 15px;
    border-right: 1px dashed #ccc;
    min-width: 0;
}

.scroll-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 60px;
    right: 45px;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fbfbfb;
    text-decoration: none;
    opacity: 0.7;
    text-decoration: none !important;
}

.remove-markup * pre {
    display: none;
}