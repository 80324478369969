.content {
    display: flex;
    height: 100%;
}
.menu {
    width: 310px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fbfbfb;
    box-shadow: 0 0 20px #bf7fdd;
    border-right: 3px solid #dcd7fa;
    margin-bottom: 65px;
}

.menu-content {
    font-family: 'Inconsolata', monospace;
    padding: 10px;
    color: #6b6b6b;
}

.menu-spacer {
    height: 50px;
    width: 100%;
}

.menu-title {
    font-size: 25px;
    color: #e87d9a;
    padding: 10px 0;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.menu-title>.fas {
    color: #d4d4d4;

}

.menu-links-wrapper {
    color: #333;
    margin-left: 5px;
    padding-left: 10px;
    cursor: pointer;
    border-left: 1px dashed #b5b5b5;
}

.menu-link {
    display: block;
    padding: 7px 0;
    letter-spacing: 1px;
    font-size: 17px;

}

.menu-links-wrapper>a:link,
.menu-links-wrapper>a:visited {
    color: #333;
    text-decoration: none;
}

.menu-links-wrapper>a:hover,
.menu-links-wrapper>div:hover {
    color: #e85f84;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.menu-link>.fas {
    color: #a7a4d7;

}

.menu-link:hover>.fas {
    color: #7e7abd;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.menu-hide > .menu-links-wrapper {
    display: none;
}

.menu-hide > .menu-title {
    padding-bottom: 0px;
}

.menu-show > .menu-links-wrapper {
    display: block;
}

.menu-show div .fa-angle-down {
	-webkit-animation: rotateOn 0.15s linear 1 forwards;
	        animation: rotateOn 0.15s linear 1 forwards;
}

.menu-hide div .fa-angle-down {
	-webkit-animation: rotateOff 0.15s linear 1 forwards;
	        animation: rotateOff 0.15s linear 1 forwards;
}
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    font-family: 'Inconsolata', monospace;
    height: 55px;
    color: #ffffff;
    margin-top: 65px;
    font-size: 17px;
    color: #333;
    background: -webkit-gradient(linear, left top, right top, from(#b684d8), color-stop(#dcd9f7), to(#b684d8));
    background: linear-gradient(to right, #b684d8, #dcd9f7, #b684d8);
}

.footer>a>.fa-atom {
    -webkit-animation: load 3s linear infinite;
            animation: load 3s linear infinite;
}

.footer>a>.fa-fort-awesome {
    -webkit-animation: bounce 1s linear infinite;
            animation: bounce 1s linear infinite;
}

.footer>.fas {
    color: #d84973;
}

.footer>a {
    color: #333 !important;
}

.footer>a:link,
.footer>a:visited {
    color: #333;
    text-decoration: none;
}

.scroll-top>.fas {
    font-size: 36px;
    color: #6b6b6b;
}

.visibility-hidden {
    visibility: hidden
}

.scroll-top-show {
    opacity: 0.7 !important;
    -webkit-transition: all 0.5s ease !important;
    transition: all 0.5s ease !important;
}

.scroll-top-show:hover {
    opacity: 1 !important;
    -webkit-transition: opacity 0.5s ease !important;
    transition: opacity 0.5s ease !important;
}

.scroll-top-hide {
    opacity: 0 !important;
    -webkit-transition: opacity 0.5s ease !important;
    transition: opacity 0.5s ease !important;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    height: 66px;
    font-size: 23px;
    color: #6b6b6b;
    background: #fbfbfb;
    box-shadow: 0 0 2px #b9b9b9;
}

.header>div>.fa-signature {
    color: #d84973;
    margin: 0 10px;
    font-size: 20px;
}

.header>span {
    color: #14070b;
}

.header-theme {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 10px;
}

.header-theme>.fa-adjust {
    color: #6b6b6b;
    font-size: 25px;
}

.paypal-real {
    margin-right: 10px;
}

.paypal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    font-size: 12px;
    color: #505050;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffdbad), to(#ffc374));
    background-image: linear-gradient(to bottom, #ffdbad, #ffc374);
    border: 1px solid #ffba5f;
    border-radius: 3px;
    padding: 6px 8px;
    position: absolute;
    right: 10px;
    bottom: 17px;
    pointer-events: none;
}

.paypal-img {
    width: 130px;
    height: 35px;
}

.fa-paypal {
    margin-left: 5px;
    font-size: 18px;
}
.splash {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    height: 100vh;
    width: 100%;
    opacity: 0;
    -webkit-animation: fadeIn 0.8s linear forwards;
            animation: fadeIn 0.8s linear forwards;
}

.splash-inner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.splash-logo {
    font-size: 33px;
    color: #fbfbfb;
    margin-bottom: 50px;
}

.splash-logo>span {
    color: #d84973;
}

.splash-logo>.fas {
    color: #d84973;
    margin-right: 10px;
}

.splash-dot-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 50px;
}

.splash-fade-out {
    -webkit-animation: fadeOut 0.8s linear forwards;
            animation: fadeOut 0.8s linear forwards;
}

/* Spinner one */

.spinnerOne-init:before,
.spinnerOne-init:after {
    content: "";
    position: relative;
    display: block;
}

.spinnerOne-init:before {
    -webkit-animation: spinnerOne 2.05s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
            animation: spinnerOne 2.05s cubic-bezier(0.75, 0, 0.5, 1) infinite normal;
    width: 52px;
    height: 52px;
    background-color: #fbfbfb;
}


/* Spinner two */

.box {
    -webkit-animation: spinnerTwo 0.5s linear infinite;
            animation: spinnerTwo 0.5s linear infinite;
    width: 52px;
    height: 52px;
    background: #fbfbfb;
    border-radius: 3px;
}
.flex1 {
    display: flex;
    flex-direction: column;
}

.flex1-wrapper-before {
    align-items: center;
}

.flex1-wrapper-before,
.flex1-wrapper-after {
    display: flex;
    min-height: 150px;
    padding: 20px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #a89cd5;
}

.flex1-wrapper-after > div {
    display: block;
}

.flex1-wrapper-inner {
    display: flex;
    justify-content: space-around;
    flex: 1 1;
}

.flex1-wrapper-after>div>example-box {
    width: 50px;
}

.flex1-wrapper-after>.flex1-wrapper-inner>example-box:nth-child(2) {
    margin: 10px 0;
}

.flex1-header {
    background-color: #d0aded;
    color: #fffdf2;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.flex1-wrapper-inner > div {
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: none;
    border-bottom: none;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div:first-child {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.flex1-wrapper-after > .flex1-wrapper-inner > div:last-child {
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
}
/* PrismJS 1.15.0
https://prismjs.com/download.html#themes=prism-tomorrow&languages=markup+css+clike+javascript */
/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

 pre.language-markup {
	border-left: 1px solid #5d5d5d;
	border-radius: 3px;
}

.language-css:first-child {
   border-top-left-radius: 3px;
   border-top-right-radius: 3px;
}

 code[class*="language-"],
 pre[class*="language-"] {
	 background: none;
	 font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	 text-align: left;
	 white-space: pre;
	 word-spacing: normal;
	 word-break: normal;
	 word-wrap: normal;
	 line-height: 1.5;
	 -moz-tab-size: 4;
	 tab-size: 4;
 
	 -webkit-hyphens: none;
	 -ms-hyphens: none;
	 hyphens: none;
 
 }
 
 /* Code blocks */
 pre[class*="language-"] {
	 padding: 1em;
	 margin: .5em 0;
	 overflow: auto;
	 border: 1px solid #5d5d5d;
	 margin: 0;
	 -webkit-transition: border 0.5s ease;
	 transition: border 0.5s ease;
 }
 
.language-markup {
	margin: 12px 0 !important;
	border-radius: 3px;
}
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
	 padding: .1em;
	 border-radius: .3em;
	 white-space: normal;
 }
 
 .token.comment,
 .token.block-comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
	 color: #999;
 }
 
 .token.tag,
 .token.attr-name,
 .token.namespace,
 .token.deleted {
	 color: #ed8fa9;
 }
 
 .token.function-name {
	 color: #6196cc;
 }
 
 .token.boolean,
 .token.number,
 .token.function {
	 color: #f08d49;
 }
 
 .token.property,
 .token.class-name,
 .token.constant,
 .token.symbol {
	 color: #7ec699;
 }
 
 .token.selector,
 .token.important,
 .token.atrule,
 .token.keyword,
 .token.builtin {
	 color: #ed8fa9;
 }
 
 .token.string,
 .token.char,
 .token.attr-value,
 .token.regex,
 .token.variable {
	 color: #7ec699;
 }
 
 .token.operator,
 .token.entity,
 .token.url {
	 color: #67cdcc;
 }
 
 .token.important,
 .token.bold {
	 font-weight: bold;
 }
 .token.italic {
	 font-style: italic;
 }
 
 .token.entity {
	 cursor: help;
 }
 
 .token.inserted {
	 color: green;
 }
.flex2 {
    display: flex;
    flex-direction: column;
}

.flex2-wrapper {
    background: #a89cd5;
    padding: 20px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.flex2-wrapper>div>example-box {
    width: auto;
}

.flex2-header {
    background: #d0aded;
    color: #fffdf2;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.flex2-example1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    margin-bottom: 20px;
}

.flex2-example1 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example1 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}






.flex2-example2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    margin-bottom: 20px;
}

.flex2-example2 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: none;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example2 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2 1;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example2 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2 1;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}







.flex2-example3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    margin-bottom: 20px;
}

.flex2-example3 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: auto;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example3 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: initial;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}











.flex2-example4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1;
    margin-bottom: 20px;
}

.flex2-example4 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 4 1;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example4 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 4 1;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example4 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 50%;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}
.flex3-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex3-wrapper-inner > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    width: 50px;
    height: 50px;
    background: #79d8e4;
    border: 2px solid #fff;
}
.flex4-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex5-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.flex6-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.flex7-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex8-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.flex9-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.flex10-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex11-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.flex12-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex13-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.flex14-wrapper {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 20px;
}

.flex14-wrapper:last-child {
    align-items: center;
}

.flex14-baseline-1 {
    padding: 55px 10px 10px;
    margin-right: 10px;
}

.flex14-baseline-2 {
    padding: 10px 10px 60px;
    margin-right: 10px;
}

.flex14-baseline-3 {
    padding: 55px 10px 10px;
    margin-right: 10px;
}

.flex14-baseline-4 {
    padding: 10px 10px 60px;
}
.flex15-wrapper {
    display: flex;
    justify-content: center;
}

.flex15-wrapper>example-box {
    padding: 0 10px;
    min-width: 80px;
}

.flex15-align-self-1 {
    align-self: flex-end;
    margin-right: 10px;
}

.flex15-align-self-2 {
    align-self: center;
    margin-right: 10px;
}

.flex15-align-self-3 {
    align-self: flex-start;
    margin-right: 10px;
}

.flex15-align-self-4 {
    align-self: flex-end;
    margin-right: 10px;
}
.flex16-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex16-wrapper > example-box:nth-child(2) {
    margin: 0 10px;
}
.flex17-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.flex17-wrapper > example-box:nth-child(2) {
    margin: 0 10px;
}
.flex18-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex18-wrapper>example-box:nth-child(2) {
    margin: 10px 0;
}
.flex19-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.flex19-wrapper>example-box:nth-child(2) {
    margin: 10px 0;
}
.flex20-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex20-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 265px;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex20-no-wrap {
    display: flex;
    flex-wrap: nowrap;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: auto;
}

.flex20-no-wrap {
    padding: 10px;
}

.flex20-no-wrap>example-box {
    margin-right: 10px;
}

.flex20-no-wrap>example-box:last-child {
    margin-right: 0;
}

.flex20-wrap>example-box {
    margin: 10px 0 10px 10px;
}

.flex20-wrap>example-box:nth-child(5),
.flex20-wrap>example-box:last-child {
    margin: 0 0 10px 10px;
}
.flex21-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex21-wrap {
    display: flex;
    flex-wrap: wrap-reverse;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: 265px;
}

.flex21-no-wrap {
    display: flex;
    flex-wrap: nowrap;
    background-color: #8a79cc;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
    width: auto;
}

.flex21-no-wrap {
    padding: 10px;
}

.flex21-no-wrap>example-box {
    margin-right: 10px;
}

.flex21-no-wrap>example-box:last-child {
    margin-right: 0;
}

.flex21-wrap>example-box {
    margin: 10px 0 10px 10px;
}

.flex21-wrap>example-box:nth-child(5),
.flex21-wrap>example-box:last-child {
    margin: 10px 0 0 10px;
}
.css1-truncate-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #8a79cc;
    border-radius: 3px;
    color: #fffdf2;
    border: 2px solid #fbfbfb;
    padding: 10px;
}

.css1-truncate-wrapper:first-child {
    margin-bottom: 10px;
}

.css1-truncate-width {
    width: 200px !important;
}

.css1-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    line-height: initial;
    padding-right: 10px;
}
.css3-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.css3-hover {
    padding: 0 10px;
}

.css3-hover:hover {
    cursor: pointer;
    color: #fffdf2;
    background-color: #a89cd5;
}

.parent-1,
.parent-2,
.parent-3,
.parent-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: arial;
  background: #a89cd5;
  padding: 20px;
  margin-bottom: 2px;
}

.parent-1 > .target-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9effc1;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.child-2 + .target-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff9e9e;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.parent-3 .target-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9eecff;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}

.child-4 ~ .target-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffc79e;
  border: 2px solid #fbfbfb;
  border-radius: 3px;
  padding: 0 10px;
  min-width: 50px;
  height: 50px;
}
.css3-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: arial;
    background: #a89cd5;
    min-height: 50px;
    padding: 20px;
}

.pseudo-child-3 {
    padding: 0 10px;
}

.pseudo-child-1,
.pseudo-child-2 {
    margin-bottom: 10px;
}

.pseudo-child-1,
.pseudo-child-2 {
    width: 289px;
    padding: 5px;
}

.pseudo-child-1::before {
    content:"::before pseudo element";
    padding: 5px;
    margin: 0 7px 0 0;
    border-radius: 3px;
    background: #ffe0ca;
    border: 2px solid #fbfbfb;
}

.pseudo-child-2::after {
    content:"::after pseudo element";
    padding: 5px;
    margin: 0 0 0 7px;
    border-radius: 3px;
    background: #ffe0ca;
    border: 2px solid #fbfbfb;
}

.pseudo-child-3::-moz-selection {
    color: #fffdf2; 
    background: #3ed9ff;
}

.pseudo-child-3::selection {
    color: #fffdf2; 
    background: #3ed9ff;
}
.css4-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    min-height: 50px;
    margin-bottom: 2px;
}

.css4-wrapper > example-box {
    padding: 0 10px;
}

.css4-wrapper > example-box:nth-child(2) {
    margin: 0 10px;
}

.class-child-1 {
    color: #333333;
    background-color: #ffc79e;
    cursor: pointer;
}

.class-child-1:hover {
    color: #fffdf2;
    background-color: #a89cd5;
}

.class-child-2:first-child,
.class-child-3:nth-child(1) {
    background-color: #9effc1;
}

.class-child-3:nth-child(2) {
    background-color: #ff9e9e;
}

.class-child-2:last-child,
.class-child-3:nth-child(3) {
    background-color: #9eecff;
}

.class-child-3:first-of-type {
    background-color: #9effc1;
}

.class-child-3:last-of-type {
    background-color: #9eecff;
}
.sections {
    display: flex;
    justify-content: center;
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 65px;
}

.sections-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin: 0 20px;
}

.section-text-list {
    border-left: 5px solid #b18bd7;
    margin-left: 6px;
    padding-left: 10px;
}

.theme {
    height: 100%;
    border-left: 5px solid #da849a;
}

.section {
    display: flex;
    background: #fbfbfb;
    border-radius: 5px;
    border-bottom: 3px solid #ffccdc;
    border-left: 3px solid #ffccdc;
}

.section-title {
    color: #6b6b6b;
    font-size: 40px;
    margin-bottom: 10px;
    font-family: 'Slabo 27px', serif;
}

.section-title > .fas {
    position: relative;
    top: 3px;
    margin-right: 6px;
    color: #ec91aa;
}

.section-spacer-top {
    height: 35px;
    width: 100%;
}

.section-spacer {
    height: 25px;
    width: 100%;
}

.section-text {
    color: #777777;
    line-height: 24px;
    margin-bottom: 20px;
}

.section-read {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;  
}

.section-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 140px;
    cursor: pointer;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.section-copy:hover {
    margin-bottom: 20px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.section-copy-text {
    background: #fffef8;
    margin-top: 10px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-family: 'Inconsolata', monospace;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.section-copy>span {
    display: none;
}

.section-copy:hover>.fas {
    color: #8ccec3;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.section-copy:hover>.section-copy-text {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.section-copy>.fas {
    font-size: 60px;
    color: #d69cd1;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
}

.section-read>.fas {
    font-size: 60px;
    color: #8ccec3;
}

.section-content {
    flex: 1 1;
    padding: 15px;
    border-right: 1px dashed #ccc;
    min-width: 0;
}

.scroll-top {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 60px;
    right: 45px;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fbfbfb;
    text-decoration: none;
    opacity: 0.7;
    text-decoration: none !important;
}

.remove-markup * pre {
    display: none;
}
