.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    height: 66px;
    font-size: 23px;
    color: #6b6b6b;
    background: #fbfbfb;
    box-shadow: 0 0 2px #b9b9b9;
}

.header>div>.fa-signature {
    color: #d84973;
    margin: 0 10px;
    font-size: 20px;
}

.header>span {
    color: #14070b;
}

.header-theme {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 10px;
}

.header-theme>.fa-adjust {
    color: #6b6b6b;
    font-size: 25px;
}

.paypal-real {
    margin-right: 10px;
}

.paypal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    font-size: 12px;
    color: #505050;
    background-image: linear-gradient(to bottom, #ffdbad, #ffc374);
    border: 1px solid #ffba5f;
    border-radius: 3px;
    padding: 6px 8px;
    position: absolute;
    right: 10px;
    bottom: 17px;
    pointer-events: none;
}

.paypal-img {
    width: 130px;
    height: 35px;
}

.fa-paypal {
    margin-left: 5px;
    font-size: 18px;
}