.flex2 {
    display: flex;
    flex-direction: column;
}

.flex2-wrapper {
    background: #a89cd5;
    padding: 20px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.flex2-wrapper>div>example-box {
    width: auto;
}

.flex2-header {
    background: #d0aded;
    color: #fffdf2;
    padding: 10px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.flex2-example1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
}

.flex2-example1 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example1 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}






.flex2-example2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
}

.flex2-example2 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: none;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example2 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example2 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}







.flex2-example3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
}

.flex2-example3 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: auto;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example3 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: initial;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}











.flex2-example4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-bottom: 20px;
}

.flex2-example4 > example-box:first-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 4;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example4 > example-box:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 4;
    height: 50px;
    padding: 0 10px;
    margin-right: 20px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}

.flex2-example4 > example-box:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 50%;
    height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid #fbfbfb;
}