.css1-truncate-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #8a79cc;
    border-radius: 3px;
    color: #fffdf2;
    border: 2px solid #fbfbfb;
    padding: 10px;
}

.css1-truncate-wrapper:first-child {
    margin-bottom: 10px;
}

.css1-truncate-width {
    width: 200px !important;
}

.css1-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
    padding-right: 10px;
}